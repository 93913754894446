import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import picGame from '../assets/images/thegame.png'
/*import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'*/

import picSaltoAlto from '../assets/images/salto_in_alto.jpg'
import picSaltoAsta from '../assets/images/salto_asta.jpg'
import picLungo from '../assets/images/saltoInLungo.jpeg'
import picLanci from '../assets/images/lanci.jpeg'
import picPista from '../assets/images/pista.jpeg'
//import picCompleto from '../assets/images/completo.jpeg'
import picGiocoCompleto from '../assets/images/giocoCompleto.png'


class Homepage extends React.Component {
    render() {
        const siteTitle = "Athletic Soul";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2><strong>Athletic Soul</strong> <br />
                                </h2>
                            </header>
                        </div>
                        <div className="col-12">
                            <span className="image fit"><img src={picGame} alt="" /></span>
                        </div>
                        <div className="col-12">
                            <p>Non è solamente un gioco da tavolo è un'esperienza di atletica interattiva che non dimenticherai mai!</p>
                            <p>Athletic soul è il gioco che ti permetterà di vivere l'atletica in tutte le sue forme. Formazione, concentrazione e competitività. Il desiderio di superare i propri limiti, di migliorare se stessi. Ora puoi provare queste emozioni ... Athletic Soul un nuovo concetto di passatempo, le sfide all'ultimo salto ti aspettano, le battaglie per la medaglia d'oro ti attendono, quindi allenamento, allenamento e allenamento, perché la fortuna in questo gioco ha una vita breve! Solo i migliori gareggeranno per la vittoria.</p>
                        </div>
                    </div>
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>Athletic Soul</h2>
                            </header>
                           
                        </div>
                        <div className="col-12">
                            <span className="image fit"><img src={picGiocoCompleto} alt="" /></span>
                            <h3>Il Gioco </h3>
                            <p>Contattaci <strong>ADESSO</strong> per informazioni!</p>
                            <ul className="actions">
                                <li><a href="mailto:info@athleticsoul.org?subject=INFOGIOCO" className="button">Contattaci</a></li>
                            </ul>
                        </div>

                    </div>
                </section>


            </Layout>
        );
    }
}

export default Homepage;